html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, .87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, .6)
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: .0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: .03125em
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000
}

  .cdk-overlay-container:empty {
    display: none
  }

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  transition: opacity .4s cubic-bezier(.25,.8,.25,1);
  opacity: 0
}

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
  }

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: .6
}

.cdk-overlay-dark-backdrop {
  background: #00000052
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear,opacity 1ms linear;
  visibility: hidden;
  opacity: 1
}

  .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0;
    visibility: visible
  }

.cdk-overlay-backdrop-noop-animation {
  transition: none
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}
